<script>
export default {
    name: "Welcome",
    computed: {
        userNbInfo() {
            return this.$store.state.userNbInfo;
        },
        migoBindLiffId() {
            return this.$store.state.migoBindLiffId;
        },
    },
    watch: {
        userNbInfo: function () {
            let vm = this;
            if (vm.userNbInfo.data.user.isBind) {
                vm.$router.push("/");
            }
        },
    },
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
